import $ from 'jquery'
import Rails from '@rails/ujs'

function onSuccessfulReturnFromGoogle(response) {
  // google authentication succeed, now post data to server.
  Rails.ajax({
    type: 'POST',
    url: '/users/auth/google_oauth2/callback',
    data: new URLSearchParams(response).toString(),
    success: (data) => {
      // devise sign in resets the session
      $('meta[name=csrf-token]').prop('content', data.csrf_token)
      Rails.ajax({
        type: 'POST',
        url: '/logged_in',
        success: (response) => {
          $('#logged_in_status').append(response.status)
          window.location = '/'
        }
      })
    }
  })
}

// From https://github.com/zquestz/omniauth-google-oauth2
window.google_auth_init = function() {
  gapi.load('auth2', function() {
    // Ready.
    $('#google_login').click(function(e) {
      e.preventDefault()

      gapi.auth2.authorize({
        client_id: $('meta[name=google_client_id]').prop('content'),
        cookie_policy: 'single_host_origin',
        scope: 'email',
        response_type: 'code'
      }, function(response) {
        if (response && !response.error) {
          onSuccessfulReturnFromGoogle(response)
        } else {
          alert('Google authentication failed')
        }
      })
    })
  })
}
